export const environment = {
    production: true,
    environmentName: 'qa',
    baseUrl:'https://qa.spellinghero.app/',
    apiUrl:'https://ui-interaction-152468579817.northamerica-northeast1.run.app/',
    region:'northamerica-northeast1',
    recaptchaSiteKey:"6LfNJjYqAAAAAHf2dpKXQ4_JmJCePARGoxi2fo-g",
    stripePublishableKey:"pk_test_51NeMKILUPLIOScuQxCHbwwK7vYeFPqdyhfFxaPsQsENsv6DUA8JpudUPP6BFp785CDoppckJrf9ASZQsUVdQL5TG00F4elkxzw",
    firebase: {
      apiKey: "AIzaSyACdblqU42a5oCNnszbHDUE2wezr9Ie0zw",
      authDomain: "spelling-app-qa.firebaseapp.com",
      projectId: "spelling-app-qa",
      storageBucket: "spelling-app-qa.appspot.com",
      messagingSenderId: "152468579817",
      appId: "1:152468579817:web:99604004a1f95f38699778",
      measurementId: "G-S9G3Y6XTVK"
      // ... other Firebase config
    }
  };